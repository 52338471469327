import validateCuil from "./validateCUIL";
const rules = {
  required: [v => !!v || "Campo requerido"],
  noSpaces: v => {
    return (v => !/\s/.test(v) || "No se permiten espacios");
  },
  decimalGreaterThanZero: valor => {
    return (!!valor && valor > 0) || "El valor debe ser mayor a 0";
  },
  decimalValidRanges: (valor, desde, hasta) => {
    return (
      (valor !== null && valor >= desde && valor <= hasta) ||
      `Valor entre ${desde} y ${hasta}`
    );
  },
  decimalEqualOrLess: (valor, hasta) => {
    return (
      (valor !== null && valor <= hasta) || `Valor igual o menor a ${hasta}`
    );
  },
  lowerCase: v => /^[a-z0-9-]*$/.test(v) || "El texto debe estar en minúsculas",
  requiredAcceptZero: [
    v =>
      (Number.isInteger(Number(v)) && v >= 0 && v !== null) || "Campo requerido"
  ],
  requiredAcceptZeroDecimal: [
    v =>
      ((Number.isNaN(Number(v)) || !Number.isNaN(Number(v))) && v !== null) ||
      "Campo requerido"
  ],

  positiveNumber: [
    v => (Number.isInteger(Number(v)) && v >= 0) || "Formato no válido"
  ],
  positiveNumber36500: [
    v =>
      (Number.isInteger(Number(v)) && v >= 0 && v <= 36500) ||
      "Formato no válido"
  ],
  positiveNumberHigherThanCero: [
    v => (Number.isInteger(Number(v)) && v > 0) || "Formato no válido"
  ],
  nonZero: [v => v != 0 || "Formato no válido"],
  nonZeroNotRequired: [
    v => v === "" || v != 0 || "El valor debe ser mayor a 0"
  ],

  validDay: [v => !v || (v > 0 && v < 29) || "Ingrese una fecha válida"],
  terminacionCuit: [
    v => !v || (v >= 0 && v < 10) || "Solo se aceptan números del 0 al 9"
  ],
  email: [
    v =>
      !v ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Formato no válido"
  ],
  counter: [v => /^[\s\S]{0,200}$/.test(v) || "Máx. 200 caracteres"],
  decimal: [
    v =>
      /^[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
      "Formato no válido para números decimales"
  ],
  decimalSi: [
    v =>
      /^(?!0\d{1,17})(\d{1,18}|\d{1,18},\d{1,2})$/.test(v) ||
      "Formato no válido para números decimales"
  ],
  decimalMil: [
    v =>
      /^(?:\d{1,3}(?:\.\d{0,2})?)$/.test(v) ||
      "Se permite un porcentaje de hasta 999.99"
  ],
  numberInteger20: [
    v => /^[0-9]{1,20}$/.test(v) || "Formato no válido para números enteros"
  ],
  telephone: [v => !v || /^[0-9]*$/.test(v) || "Teléfono no válido"],
  telephoneRequired: [
    v =>
      !!v ||
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v) ||
      "Ingrese un teléfono válido"
  ],
  numberInteger: [
    v =>
      /^[0-9]{1,3}$/.test(v) || "Formato no válido para números menores a 999"
  ],

  numberInteger2: [
    v => /^[0-9]{1,2}$/.test(v) || "Formato no válido para números menores a 99"
  ],
  password: [
    v =>
      (!!v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v)) ||
      "Min. 8 caracteres con al menos una mayúscula y un número"
  ],
  periodoYyyyMm: [
    // valid input between 199001 and 999912
    v =>
      !v ||
      /^([1-9][0-9][0-9][0-9])(0[1-9]|1[0-2])$/.test(v) ||
      "Formato no válido"
  ],
  periodoYyyyMmDd: [
    v =>
      !v ||
      /^([1-9][0-9][0-9][0-9])(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[0-1])$/.test(
        v
      ) ||
      "Formato no válido"
  ],
  formatoPeriodoName: [
    v =>
      !v ||
      /^(((0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4})|(\d{6})|(\d{4})|(\d{2}(0[1-9]|1[0-2]))|((20|21)\d{2}(0[1-9]|1[0-2]))|(\d{2}\/(0[1-9]|1[0-2]))|(([jJ][aA][nN]|[fF][eE][bB]|[mM][aA][rR]|[aA][pP][rR]|[mM][aA][yY]|[jJ][uU][nN]|[jJ][uU][lL]|[aA][uU][gG]|[sS][eE][pP]|[oO][cC][tT]|[nN][oO][vV]|[dD][eE][cC])\s(0?[1-9]|[12][0-9]|3[01]))|(\d{8}))$/.test(
        v
      ) ||
      "Formato no válido"
  ],
  formatoMesDia: [
    v =>
      !v ||
      /^(ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic)\s\d{1,2}$/i.test(v) ||
      "Formato no válido"
  ],
  max99: [
    v =>
      (!!v && Number.isInteger(Number(v)) && v >= 0 && v <= 99) ||
      "Número entre 0 y 99"
  ],
  maxMen100: [
    v =>
      (!!v && Number.isInteger(Number(v)) && v >= -100) || "Número hasta -100"
  ],
  maxLength6: [
    v =>
      (!!v && Number.isInteger(Number(v)) && v >= 0 && v.length <= 6) ||
      "Máximo de 6 dígitos"
  ],
  positiveNumberHigher0: [
    v => (Number.isInteger(Number(v)) && v > 0) || "Formato no válido"
  ],
  max99999: [
    v =>
      (!!v && Number.isInteger(Number(v)) && v >= 0 && v <= 99999) ||
      "Número entre 0 y 99999"
  ],
  max100: [
    v =>
      (!!v && Number.isInteger(Number(v)) && v >= 0 && v <= 100) ||
      "Número entre 0 y 100"
  ],

  validDate: [
    v =>
      /^((((0?[1-9]|1\d|2[0-8])\/(0?[1-9]|1[0-2]))|((29|30)\/(0?[13-9]|1[0-2]))|(31\/(0?[13578]|1[02])))\/(19|[2-9]\d)\d{2})$|^(29\/0?2\/(19|[2-9]\d)(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96))$/.test(
        v
      ) || "Formato de fecha no válido"
  ],

  maxDigitsDecimal: (digitsBefore, digitsAfter) => {
    return [
      v => {
        const regex = new RegExp(
          `^\\d{0,${digitsBefore}}(\\.\\d{0,${digitsAfter}})?$`
        );
        return (
          regex.test(v) ||
          `Formato no válido. Máximo ${digitsBefore} dígitos antes del separador decimal y ${digitsAfter} dígitos después`
        );
      }
    ];
  },
  maxDecimal100: () => {
    return [
      v => {
        if (v === null || v.trim() === "") {
          return true; // No se muestra mensaje de error si el valor es nulo o está vacío
        }
        const regex = /^(\d{1,2}([.,]\d{0,2})?|100([.,]0{0,2})?)$/;
        return (
          regex.test(v) ||
          "Formato no válido. El valor debe ser un número decimal entre 0 y 100 con hasta 2 decimales."
        );
      }
    ];
  },
  maxDigitsDecimalNegative: (digitsBefore, digitsAfter) => {
    return [
      v => {
        const regex = new RegExp(
          `^-?\\d{0,${digitsBefore}}(\\.\\d{0,${digitsAfter}})?$`
        );
        return (
          regex.test(v) ||
          `Formato no válido. Máximo ${digitsBefore} dígitos antes del separador decimal y ${digitsAfter} dígitos después`
        );
      }
    ];
  },
  requiredTrim: value => {
    return value =>
      value == null ||
      !value.startsWith(" ") ||
      "No se permite iniciar con espacio";
  },
  maxLength: (value, limit) => {
    return value =>
      !value || `${value}`.length <= limit || `Máximo ${limit} caracteres`;
  },
  minLength: (value, limit) => {
    return value =>
      !value || `${value}`.length >= limit || `Mínimo ${limit} caracteres`;
  },
  validCodigoPostal: [v => !v || v <= 32767 || "Código postal incorrecto."],
  //vale tanto para fecha como para periodo
  validDateRange: (fechaDesde, fechaHasta) => {
    return fechaDesde <= fechaHasta || "Rango no válido";
  },
  validDateRangeNumber: (numeroDesde, numeroHasta) => {
    if(!numeroDesde && !numeroHasta) return true;
    const desde = parseInt(numeroDesde, 10);
    const hasta = parseInt(numeroHasta, 10);
    return desde <= hasta || "Rango no válido";
  },
  maxNumber: (value, limit) => {
    return value =>
      !value ||
      (Number.isInteger(Number(value)) && value <= limit) ||
      `Número no mayor a ${limit}`;
  },
  minMaxNumber: (value, min, max) => {
    return value =>
      !value ||
      (Number.isInteger(Number(value)) && value >= min && value <= max) ||
      `Número entre ${min} y ${max}`;
  },
  periodoMmYy: [
    // para vencimiento tarjeta
    value =>
      !value ||
      (value.split("/")[0] < 13 && value.length === 5) ||
      "Ingrese un formato válido"
  ],
  validateEmailFormat: value => {
    return value =>
      !value ||
      (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) &&
        value.length <= 60) ||
      "Formato de email inválido";
  },
  validateUrl: [
    v =>
      /^(ftp|http|https):\/\/(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\S*)?$/.test(
        v
      ) || "Formato no válido."
  ],
  samePassword: (firstPassword, secondPassword) => {
    return firstPassword == secondPassword || "Las contraseñas no coinciden";
  },
  cuilValido: cuil => {
    return (
      (cuil != null && validateCuil.validateCUITCUIL(cuil)) || "CUIL inválido"
    );
  },
  optionalRequired: (options1, options2, message) => {
    // ejemplo optionalRequired( [ fecha1,fecha2 ], [ periDesde,periHasta ], "Debe ingresar al menos un valor" )
    return options1.every(x => x) || options2.every(y => y) || message;
  },
  IconStartWith: [
    v => v.startsWith("fa-") || "La palabra debe comenzar con 'fa-'"
  ]
};

export default rules;
