<template>
  <v-container>
    <div class="row justify-content-end">
      <div class="col-2 text-right py-3">
        <Button flat class="py-1" id="cerrar-sesion" @click="closeSession">
          <span> Cerrar sesión </span>
        </Button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8 text-center mt-0">
        <div class="home">
          <v-row>
            <v-col>
              <v-img
                alt="Vue logo"
                href
                :src="require('../assets/img/logo_int.svg')"
                class="logo"
                max-height="200"
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col
              align-self="center"
              cols="8"
              md="8"
              sm="12"
              class="mb-0 px-0 py-0 "
            >
              <v-autocomplete
                v-show="!appLoading.status"
                class=" mt-3 mb-1 text-wrap"
                autocomplete="menu-options-items"
                id="autocomplete-buscador-opciones"
                v-model="selectedOptionCard"
                hide-details
                :prepend-inner-icon="searchIcon"
                :append-icon="chevronIcon"
                label="Buscar opción de menú"
                no-data-text="Inserte mínimo tres letras o presione la tecla ENTER para buscar."
                :items="tarjetasOpcionesCombo"
                item-text="titleWithoutAccent"
                :loading="isloading"
                item-value="id"
                return-object
                clearable
                @change="pushPage"
                @keydown.enter="loadDatos"
                :search-input.sync="searchText"
                dense
                single-line
                solo
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.descOption"
                        class=" ml-1"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
          <Loader
            v-if="appLoading.status"
            :text="appLoading.text"
            :opaque="appLoading.opaque"
          />
          <v-row class="row-list" v-else>
            <v-col sm3 md2 class="content mb-4 pb-4" v-if="listModules.length">
              <div
                class="icons mt-5 list-items"
                style="flex-direction: flex-sm-column"
              >
                <transition-group tag="ul" name="fade" class="list-items w-100">
                  <li
                    v-for="(item, index) in !showMore
                      ? listModules.slice(0, 4)
                      : listModules"
                    :key="item.title + index"
                    :style="
                      `transition-delay: ` + (showMore ? 1 / index : 0) + `s`
                    "
                    class="li"
                  >
                    <div :id="`btn-${item.title}`" @click.stop="openMenu(item)">
                      <div class="icon">
                        <v-img
                          aspect-ratio="1"
                          :src="mapIcons(item)"
                          contain
                          max-height="24"
                          max-width="24"
                        ></v-img>
                      </div>
                      <p>{{ item.title }}</p>
                      <transition name="fade">
                        <div
                          class="submenu_box"
                          v-if="item.showMenu"
                          key="menu"
                          @click.stop
                          v-click-outside="closeMenus"
                        >
                          <div class="text-right">
                            <a
                              class="text-green"
                              @click.prevent="item.showMenu = false"
                              ><v-icon>mdi-close</v-icon></a
                            >
                          </div>
                          <div class="subtitle breadcrumbs">
                            <v-img
                              aspect-ratio="1"
                              :src="mapIcons(item)"
                              contain
                              max-height="24"
                              max-width="24"
                              class="mr-1"
                            ></v-img>
                            <div>
                              {{ item.title }}
                            </div>
                            <template v-if="menuActive.length">
                              <div
                                v-for="(link, i) in menuActive"
                                :key="'span' + i"
                              >
                                <span class="mx-2">/</span>
                                <a @click="changeMenu(-1, item)"
                                  >{{ link.title }}
                                </a>
                              </div>
                            </template>
                            <menu-childs
                              @updateBreadcrumb="addToMenu($event)"
                              :submenuChilds.sync="submenuChilds"
                            >
                            </menu-childs>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </li>
                </transition-group>
              </div>
              <button
                id="btn-ver-mas-menos"
                class="btn_more"
                @click.stop="toggleShowMore()"
              >
                <span>{{ showMore ? "Ver menos" : "Ver más" }}</span>
              </button>
            </v-col>
          </v-row>
          <Footer />
        </div>
      </div>
    </div>
    <ChangePassword
      id="change-password"
      @close="openChangePassword = false"
      :withoutCancel="withoutCancel"
    ></ChangePassword>
    <snack-bar />
  </v-container>
</template>
<script>
import menuChilds from "@/deprecated/components/UI/Menus/Home/menuChilds";
import Button from "@/deprecated/components/Buttons/Button";
import { mapGetters } from "vuex";
import Footer from "@/components/layout/Footer";
import enums from "@/utils/enums/index";
import mapIcons from "@/utils/helpers/mapIcon";
import Loader from "@/deprecated/components/Loader/Loader";
import axios from "axios";
import helper from "@/utils/helpers";
import { mapActions } from "vuex";
import ChangePassword from "@/components/profile/ChangePassword";
import SnackBar from "@/components/layout/SnackBar";

export default {
  name: "home",
  components: {
    menuChilds,
    Button,
    Footer,
    Loader,
    ChangePassword,
    SnackBar
  },
  data() {
    return {
      showMore: false,
      submenuChilds: [],
      menuActive: [],
      helper: helper,
      enums: enums,
      isloading: false,
      selectedOptionCard: null,
      searchIcon: enums.icons.SEARCH,
      chevronIcon: enums.icons.CHEVRON_DOWN,
      tarjetasOpcionesCombo: [],
      searchText: "",
      userCurrentlyLogged: true,
      openModalChangePassword: false,
      withoutCancel: false
    };
  },
  watch: {
    searchText() {
      if (this.searchText?.length > 2) {
        this.searchText = this.removeAccents(this.searchText.toLowerCase());
        this.loadDatos();
      } else if (!this.searchText) {
        this.tarjetasOpcionesCombo = [];
      }
    },
    appLoading() {
      if (!this.appLoading.status) this.validatePass();
    }
  },
  computed: {
    ...mapGetters({
      listModules: "user/modulos",
      appLoading: "user/appLoading",
      userLogged: "user/user"
    })
  },
  beforeCreate() {
    this.$store.dispatch("user/setUser");
  },
  created() {
    if (this.listModules.length === 0) {
      this.$store.dispatch("user/getModulos");
    }
    this.closeMenus();
  },
  methods: {
    ...mapActions({
      getGecrosVueModules: "shared/getGecrosVueModules",
      getUserPassStatus: "user/getUserPassStatus",
      setAlert: "user/setAlert"
    }),
    mapIcons(item) {
      let icon;
      if (item != undefined) icon = mapIcons(item);
      return icon.img;
    },
    addToMenu(objLink) {
      this.menuActive.push(objLink);
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    closeSession() {
      this.$store.dispatch("user/setLogoutMvc", () => {
        localStorage.removeItem("token");
        this.$router.push("/login");
      });
      this.userCurrentlyLogged = false;
    },
    openMenu(item) {
      this.menuActive = [];
      this.closeMenus();
      item.showMenu = true;
      this.submenuChilds = item;
    },

    closeMenus() {
      this.listModules.forEach(element => {
        element.showMenu = false;
      });
    },
    changeMenu(position, item) {
      this.menuActive.splice(position + 1, this.menuActive.length);
      this.submenuChilds = item;
    },
    openChangePassword(open) {
      this.openModalChangePassword = open;
      this.$bus.$emit("openModal", this.openModalChangePassword);
    },
    async loadDatos() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (this.searchText?.length > 1) {
          let busqueda = this.helper.removeAccents(
            this.searchText.toLowerCase()
          );
          this.isloading = true;
          if (busqueda) {
            // si el usuario no esta logeado, no vuelve a llamar a la base de datos en user/getOpcionesSearch
            // esto sucede porque cuando el usuario desloggea, el watch de searchText pasa por este condicional
            // se rompe en gecrossuperusuario
            if (!this.userCurrentlyLogged) return;

            const response = await this.$store.dispatch(
              "user/getOpcionesSearch",
              busqueda
            );
            this.tarjetasOpcionesCombo = response;
            this.tarjetasOpcionesCombo.forEach(element => {
              // necesito filtrar las opciones con nombres iguales y asignarle un indice para que el autocomplete pueda diferenciarlos.
              let arrayTitlesIguales = this.tarjetasOpcionesCombo.filter(
                y => y.titleWithoutAccent == element.titleWithoutAccent
              );
              if (arrayTitlesIguales.length > 1) {
                let index = this.tarjetasOpcionesCombo.indexOf(element);
                this.tarjetasOpcionesCombo[
                  index
                ].titleWithoutAccent = `${element.titleWithoutAccent} ${index}`;
              }
            });
          }
        }
        this.tarjetasOpcionesCombo.filter(
          element => !(element.path[0] == "/" || element.path[0] == ">")
        );
        this.isloading = false;
      }, 500);
    },
    async pushPage() {
      // Si es superUsuario y quiere ir a pantalla de mvc, volver al login
      if (this.userLogged.usuarioNombre === "gecrossuperusuario") {
        const modulos = await this.getGecrosVueModules();
        const isGecrosVueModule = modulos.some(
          x => x.id == this.selectedOptionCard.optionId
        );
        if (!isGecrosVueModule) {
          this.closeSession();
          this.userLogged = null;
          return;
        }
      }
      if (this.selectedOptionCard) {
        let externalAction = `${window.location.origin}/redirto?id=${this.selectedOptionCard.path}`;
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
        if (this.selectedOptionCard.frameWork != 1) {
          const option = await axios.post(
            `api/menu/options/${this.selectedOptionCard.optionId}`
          );
          const actions = localStorage.getItem("allowedActions");
          if (actions) {
            localStorage.removeItem("allowedActions");
          }
          localStorage.setItem(
            "allowedActions",
            JSON.stringify(option.data?.children)
          );

          this.$router.push(`/${this.selectedOptionCard.path}`);
        } else {
          this.selectedOptionCard.externalLink = externalAction;
          this.$router.push({
            name: "mvc",
            params: {
              url: encodeURIComponent(this.selectedOptionCard.externalLink)
            }
          });
          setTimeout(async () => {
            this.$store.dispatch("user/changeAppLoading", {
              status: false,
              text: "",
              opaque: false
            });
          }, 2000);
          this.$store.dispatch(
            "user/updateFrequencyByOptionCode",
            this.selectedOptionCard.optionCode
          );
        }

        this.selectedOptionCard = null;
        this.searchText = "";
      }
    },
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async validatePass() {
      const res = await this.getUserPassStatus();
      if (res.firstLogin) {
        this.withoutCancel = true;
        this.openChangePassword(true);
        this.setAlert({
          type: "warning",
          message: "Su contraseña es insegura. Ingrese una nueva."
        });
      } else if (res.expiredPass) {
        this.withoutCancel = true;
        this.openChangePassword(true);
        this.setAlert({
          type: "warning",
          message: "Su contraseña ha caducado. Ingrese una nueva."
        });
      } else if (res.expiredSoonPass) {
        this.setAlert({
          type: "info",
          message: `Su contraseña caduca pronto. Ingrese una nueva.`
        });
      } else if (res.weakPass) {
        this.openChangePassword(true);
        this.setAlert({
          type: "warning",
          message: "Su contraseña es insegura. Ingrese una nueva."
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@media (max-width: 768px) {
  .row-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -60px;
    margin-left: -60px;
  }
}
.close_session {
  min-width: auto;
}
.btn_more {
  min-width: 200px;
}
button {
  padding: 14px;
  text-align: center;
  color: #195472;
  border: 2px solid #195472;
  border-radius: 5px;
  font-size: 16px;
  transition: background 0.3s;
  &:hover {
    background: var(--green-soft);
  }
}

.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    padding: 0px 0 12px;
    .logo {
      width: 130px;
      margin-bottom: 20px;
    }
  }
}
.list-items {
  list-style: none !important;
  padding: 0;
  display: flex;
  text-align: center;
  justify-content: center; //flex-start;
  flex-wrap: wrap;

  li {
    width: 25%;
    display: grid;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    cursor: pointer;

    .icon {
      margin: auto;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background: var(--green-soft);
      display: flex;
      justify-content: center;
      align-items: center;
      .imgIcon {
        max-width: 26px;
      }
    }
    p {
      font-size: 14px;
      margin: 10px 0 6px;
      font-weight: 700;
    }
    .submenu {
      list-style: none !important;
      position: absolute;
      top: 25px;
      //right: 25px;
      right: 0px;
      display: flex;
      height: 24px;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 4px 10px 4px 15px;
      cursor: pointer;
      transition: all 0.4s;
      span {
        list-style: none !important;
        width: 4px;
        height: 4px;
        background: #777;
        border-radius: 50%;
        transition: all 0.4s;
      }
      &:hover {
        height: 26px;
        span {
          background: #195472;
        }
      }
    }
  }
}
.submenu_box {
  list-style: none !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 12px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #195472;
  border-radius: 7px;
  min-width: 230px;
  text-align: left;
  overflow: hidden;
  cursor: context-menu;
  h5 {
    font-size: 17px;
    font-weight: bold;
  }
  .subtitle {
    color: #195472;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    img {
      margin-right: 8px;
    }
  }
}
.breadcrumbs {
  list-style: none !important;
  display: flex;
  flex-wrap: wrap;
  a {
    color: #195472;
    text-decoration: none;
    &:hover {
      color: black;
      text-decoration: none;
    }
  }
}
.sub-items {
  list-style: none !important;
  padding: 0;
  width: 100%;
  li {
    padding: 7px 0;
    border-bottom: 1px solid var(--green-soft);
    width: 100%;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      img {
        cursor: pointer;
        transition: transform 0.3s;
      }
      p {
        font-weight: lighter;
        margin: 0;
      }
    }
    &:hover {
      img {
        transform: scale(1.3);
      }
    }
  }
}
</style>
