import cuotas from "../../assets/img/icons/currency-usd.svg";
import afiliaciones from "../../assets/img/icons/account-group.svg";
import mEntrada from "../../assets/img/icons/clipboard-text.svg";
import prestaciones from "../../assets/img/icons/human-wheelchair.svg";
import email from "../../assets/img/icons/email.svg";
import appMovil from "../../assets/img/icons/cellphone.svg";
import prestadores from "../../assets/img/icons/doctor.svg";
import admSis from "../../assets/img/icons/cogs.svg";
import error from "../../assets/img/icons/cancel.svg";
import webSiteOptions from "../enums/webSiteOptions";
import facturacionOS from "../../assets/img/icons/archive-outline.svg";
import proveedores from "../../assets/img/icons/truck-delivery.svg";
import convenios from "../../assets/img/icons/handshake.svg";
import analytics from "../../assets/img/icons/analytics.svg";
import fondos from "../../assets/img/icons/fondos-icon.svg";

const iconList = [
  {
    optionCode: webSiteOptions.CUOTAS,
    img: cuotas
  },
  {
    optionCode: webSiteOptions.AFILIACIONES,
    img: afiliaciones
  },
  {
    optionCode: webSiteOptions.MESA_ENTRADA,
    img: mEntrada
  },
  {
    optionCode: webSiteOptions.PRESTACIONES,
    img: prestaciones
  },
  {
    optionCode: webSiteOptions.EMAIL,
    img: email
  },
  {
    optionCode: webSiteOptions.APLICACION_MOVIL,
    img: appMovil
  },
  {
    optionCode: webSiteOptions.PRESTADORES,
    img: prestadores
  },
  {
    optionCode: webSiteOptions.ADMINISTRACION_SISTEMA,
    img: admSis
  },
  {
    optionCode: webSiteOptions.FACTURACION_OS,
    img: facturacionOS
  },
  {
    optionCode: webSiteOptions.PROVEEDORES,
    img: proveedores
  },
  {
    optionCode: webSiteOptions.CONVENIOS,
    img: convenios
  },
  {
    optionCode: webSiteOptions.ANALYTICS,
    img: analytics
  },
  {
    optionCode: webSiteOptions.FONDOS,
    img: fondos
  }
];

const mapIcons = item => {
  let result = iconList.find(x => x.optionCode === item.optionCode);
  if (result == null) {
    result = {
      img: error
    };
  }
  return result;
};

export default mapIcons;
