<template>
  <transition name="slide" mode="out-in">
     <ul class="sub-items mt-3"  key="primary">
      <li v-for="(sub, i) in menuSelected.children" :key="i">
        <div v-if="sub.children.length" class="d-flex align-items-center mb-2" :id="`btn-menu-${sub.title}`">
            <img src="@/assets/img/right_arrow.svg" alt />
            <p class="ml-2 mb-0" @click="openSub(sub)">{{ sub.title }} </p>
        </div>
        <div v-else class="d-flex align-items-center mb-2" :id="`btn-menu-${sub.title}`">          
          <img v-if="sub.favourite" src="@/assets/img/star_on.svg" alt="staron" @click="toggleFavorite(i)"/>
          <img v-else src="@/assets/img/star_off.svg" alt="star" @click="toggleFavorite(i)"/>
          <p class="ml-2 mb-0" @click="goToPage(sub)">{{ sub.title }}</p>
        </div>        
      </li>
    </ul> 
  </transition>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import enums from '@/utils/enums/index';

export default {
  name: 'menuChilds',
  props: { 
    submenuChilds: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menuSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      listModules: 'user/modulos',
    }),
  },

  mounted() {
    this.menuSelected = this.submenuChilds;
  },
  methods: {
    async toggleFavorite(index) {
      const items = this.menuSelected.children;
      items[index].favourite = !items[index].favourite;
      await axios.post(`api/menu/favorite/${items[index].optionId}`);
    },
    openSub(objLink) {
      this.menuSelected = [];
      this.menuSelected = objLink;
      this.$emit('update:submenuChilds', objLink);
      this.$emit('updateBreadcrumb', objLink);
    },
    async goToPage(menu) {
      const moduleFound = this.listModules.find(x => x.optionId === menu.moduleId);
      if (moduleFound) {
        this.$store.dispatch('user/changeActiveMenu', moduleFound.title);
      }

      //updetear como visitado 
      const option = await axios.post(`api/menu/options/${menu.optionId}`);
      //por ultimo ingreso a la vista
      if(menu.framework === enums.frameworks.VUE){
        //me fijo si la vista tiene acciones
        //si tiene me fijo si ya existe algo en el localStorage para borrarlo
        const actions = localStorage.getItem('allowedActions');

        // TODO  validar cdo viene "null" entra igual al if
        if(actions){
          localStorage.removeItem('allowedActions');
        }
        localStorage.setItem('allowedActions', JSON.stringify(option.data?.children)); // TODO validar que el data.actions no sea null
        //grabo el modulo cargado
        const moduleLoaded = localStorage.getItem('moduleLoaded');
        // moduleLoaded.moduleId
        if(moduleLoaded){
          localStorage.removeItem('moduleLoaded');
        }
        this.$router.push(menu.path);
        localStorage.setItem('moduleLoaded', JSON.stringify(menu));
      }
      else{
        this.$store.dispatch('user/goToMvc',{ name:menu.title, url: menu.externalLink });
      }             
    }
  },
  watch: {
    submenuChilds() {
      this.menuSelected = this.submenuChilds;
    }
  },
};
</script>
<style lang="scss" scoped>
.d-flex {
  border-bottom: 1px solid #80808038;
  padding: 8px 0px;
  cursor: pointer;
}
</style>