import axios from "@/axios/axios-api";

const actions = {
  async getProcesosImportacionRelacionAfiliadoEmpresa(context, payload) {
    const response = await axios.get(
      `api/procesosAfiliado/GetProcesosImportacionRelacionAfiliadoEmpresa`
    );
    return response.data.data;
  },
  async saveProcesoImportacionAfiliadoEmpresa(context, payload) {
    const response = await axios.post(
      "api/procesosAfiliado/SaveImportacionAfiliadoEmpresa",
      payload
    );
    return response.data.data;
  },
  async anularProcesoImportacionAfiliadoEmpresa(context, payload) {
    const response = await axios.get(
      `api/procesosAfiliado/AnularProcesoImportacionAfiliadoEmpresa/${payload}`
    );
    return response;
  },
  async getFormatoProcesoImportacionAfiliadoEmpresa() {
    const response = await axios.get(
      `api/procesosAfiliado/getFormatoProcesoImportacionAfiliadoEmpresa`
    );
    return response.data.data;
  },
  async getRelacionAfiEmpresaByProcess(context, payload) {
    const response = await axios.get(
      `api/procesosAfiliado/getAfiliadoEmpresaByProceso/${payload}`
    );
    return response.data.data;
  }
};
export default actions;
