<template>
  <section class="login">
    <div class="container-fluid h-100">
      <div class="row justify-content-center align-items-center h-100">
        <div class="left col-md-6">
          <div
            class="full-height d-flex flex-column justify-content-between align-items-center green-column"
          >
            <div></div>
            <img
              :src="
                customLogo != null
                  ? customLogo
                  : require('@/assets/img/logo.svg')
              "
              alt="Geclipsa"
              class="logo"
            />
            <div class="copy"></div>
          </div>
        </div>
        <div class="right col-md-6">
          <div
            class="full-height d-flex flex-column justify-content-between align-items-center white-column"
          >
            <div></div>
            <div class="login-box">
              <div
                class="text-center"
                v-show="!userTokenRequest && !userGenerateQR"
              >
                <img
                  src="@/assets/img/logo_int.svg"
                  alt="Geclipsa"
                  class="small-logo"
                  height="80px"
                  width="80px"
                />
                <h6 class="text-green">
                  <a
                    :href="`${lastVersion}${currentVersion}.pdf`"
                    style="text-decoration: none;"
                    target="_blank"
                    >{{ currentVersion }}</a
                  >
                </h6>
                <h2
                  class="rubic text-green font-weight-bold title-sign"
                  style="font-size:0.9em"
                >
                  Iniciar sesión
                </h2>
                <v-form
                  v-model="isFormValid"
                  ref="login-form"
                  id="login-form"
                  @submit.prevent="loginForm()"
                  class="text-left"
                >
                  <v-text-field
                    tabindex="1"
                    outlined
                    id="text-field-userName-login"
                    dense
                    placeholder=" "
                    label="Usuario"
                    :rules="rules.userName"
                    v-model="user.username"
                    @focus="inputActive = 'username'"
                    @blur="inputActive = ''"
                  />
                  <v-text-field
                    tabindex="2"
                    outlined
                    dense
                    id="text-field-password-login"
                    placeholder=" "
                    label="Contraseña"
                    :append-icon="
                      !user.password ? '' : textType ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="() => (textType = !textType)"
                    :type="textType ? 'password' : 'text'"
                    :rules="rules.password"
                    v-model="user.password"
                    @focus="inputActive = 'password'"
                    @blur="inputActive = ''"
                  />
                  <div class="mt-2">
                    <transition name="fade">
                      <v-alert v-if="error" dense elevation="2" type="warning"
                        >Se produjo un error. Intente nuevamente.</v-alert
                      >
                    </transition>
                  </div>
                  <div class="mt-2">
                    <transition name="fade">
                      <v-alert
                        v-if="validationError"
                        dense
                        elevation="2"
                        type="warning"
                        >Usuario o contraseña incorrectos.</v-alert
                      >
                    </transition>
                  </div>
                  <v-btn
                    tabindex="3"
                    :loading="isLoading"
                    color="primary"
                    class="mt-4"
                    id="btn-entrar-login"
                    :disabled="!isFormValid"
                    type="submit"
                    form="login-form"
                    >Entrar</v-btn
                  >
                </v-form>
              </div>
              <div class="text-center" v-if="userTokenRequest">
                <img
                  src="@/assets/img/icons/token-icon.svg"
                  alt="TokenLock"
                  class="small-logo"
                  height="80px"
                  width="80px"
                />
                <h2
                  class="rubic text-green font-weight-bold title-sign"
                  style="font-size:1.0em"
                >
                  Ingresar código de autenticación
                </h2>
                <v-form
                  v-model="isTokenFormValid"
                  ref="token-form"
                  id="token-form"
                  @submit.prevent="tokenForm()"
                  class="text-left"
                >
                  <v-row class="justify-center align-center">
                    <v-col cols="6" md="6" sm="12" class="py-0">
                      <v-text-field
                        tabindex="1"
                        outlined
                        id="text-field-token-login"
                        dense
                        placeholder=""
                        class="input-centered"
                        v-mask="'######'"
                        :rules="
                          rulesSistema.required.concat([
                            rulesSistema.minMaxNumber(token, 1000, 999999)
                          ])
                        "
                        label="Token"
                        type="text"
                        v-model="token"
                      />
                    </v-col>
                  </v-row>
                  <div v-if="tokenError">
                    <transition name="fade">
                      <v-alert dense elevation="2" type="warning"
                        >Código incorrecto. Intente nuevamente.</v-alert
                      >
                    </transition>
                  </div>
                  <v-btn
                    tabindex="2"
                    :loading="isLoadingTokenBtn"
                    color="primary"
                    :disabled="!isTokenFormValid"
                    type="submit"
                    form="token-form"
                    >Confirmar</v-btn
                  >
                  <v-divider></v-divider>
                  <p
                    class="rubic text-green font-weight-bold title-sign"
                    style="font-size:0.8em; text-align:center; cursor: pointer;"
                    @click="backToLogin()"
                  >
                    Volver al inicio de sesión
                  </p>
                </v-form>
              </div>
              <div class="text-center" v-if="userGenerateQR">
                <h2
                  class="rubic text-green font-weight-bold title-sign"
                  style="font-size:0.8em"
                >
                  {{
                    appSelected == null
                      ? "Elegir aplicación generadora de QR"
                      : "Escanear QR en la aplicación de " + appSelected
                  }}

                  <v-row v-if="appSelected == null">
                    <v-col cols="12" class="pb-0"
                      ><v-btn
                        color="blue-grey"
                        dark
                        rounded
                        small
                        @click="selectApp('Microsoft')"
                      >
                        Microsoft Authenticator</v-btn
                      ></v-col
                    >
                    <v-col cols="12"
                      ><v-btn
                        color="blue-grey"
                        dark
                        rounded
                        small
                        @click="selectApp('Google')"
                      >
                        Google Authenticator</v-btn
                      ></v-col
                    >
                  </v-row>
                  <img
                    v-if="appSelected != null"
                    :src="qrCode"
                    alt="qrCode"
                    height="200px"
                    width="200px"
                  />
                </h2>
                <v-btn
                  color="primary"
                  v-if="appSelected != null"
                  @click="goToAuthenticateCode()"
                  >Siguiente</v-btn
                >
                <v-col cols="12" class="py-0">
                  <v-divider></v-divider>
                  <p
                    class="rubic text-green font-weight-bold title-sign"
                    style="font-size:0.8em; text-align:center; cursor: pointer;"
                    @click="backToLogin()"
                  >
                    Volver al inicio de sesión
                  </p></v-col
                >
              </div>
            </div>
            <div class="copy">
              <p>
                <a
                  href="https://gecros.ar/"
                  style="text-decoration: none;"
                  target="_blank"
                  >GECROS</a
                >
                un producto de
                <a href="https://www.macena.com.ar/" target="_blank"
                  ><img src="@/assets/img/macena.svg" alt="Macena"
                /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <v-dialog
        v-if="showModalBackup"
        v-model="showModalBackup"
        max-width="50%"
        persistent
      >
        <v-card>
          <v-container>
            <v-card-title class="headline warning--text justify-center">
              Alerta
            </v-card-title>

            <v-card-text
              class="headline primary--text justify-center"
              v-if="cantDiasBackup > 1"
            >
              No se realizaron backups de la base de datos del sistema en el
              último {{ cantDiasBackup }} días.
            </v-card-text>
            <v-card-text class="headline primary--text justify-center" v-else>
              No se realizaron backups de la base de datos del sistema en los
              últimos {{ cantDiasBackup }} día.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                dark
                @click="confirmAlertAndCloseBackup"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { eVersiones } from "../src/utils/enums";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import rulesSistema from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "Login",
  directives: { mask },
  async mounted() {
    this.getLogo();
    await this.$store.dispatch("AdministracionSistema/getPublicSettings");
    //let color = '#195472';
    if (this.backgroundColor && this.backgroundColor !== "") {
      let css = `#app .login::before { background-color: ${this.backgroundColor} !important; }`,
        head = document.head || document.getElementsByTagName("head")[0];
      let style = document.createElement("style");
      head.appendChild(style);
      style.type = "text/css";
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingTokenBtn: false,
      textType: String,
      isFormValid: true,
      volverIcon: enums.icons.BACK,
      inputActive: "",
      lastVersion: eVersiones.urlVersion,
      currentVersion: eVersiones.current,
      error: false,
      rulesSistema: rulesSistema,
      validationError: false,
      user: {
        username: "",
        password: ""
      },
      rules: {
        userName: [
          v => !!v || "*",
          v =>
            (!!v && v.length <= 20 && v.length >= 1) ||
            "Entre 1 y 20 caracteres"
        ],
        password: [
          v => !!v || "*",
          v => (!!v && v.length <= 30) || "Max. 30 caracteres"
        ]
      },
      customLogo: null,
      token: null,
      tokenError: false,
      isTokenFormValid: false,
      userTokenRequest: false,
      validatedToken: false,
      userGenerateQR: false,
      qrCode: null,
      appSelected: null,
      isCompanyUser: false,
      userValidatedId: null,
      showModalBackup: false,
      cantDiasBackup: null
    };
  },
  computed: {
    ...mapGetters({
      loginUser: "user/login",
      backgroundColor: "AdministracionSistema/backgroundColor"
    })
  },
  watch: {
    async loginUser(val) {
      if (val?.validationError) {
        this.validationError = true;
        this.isLoading = false;
      } else this.validationError = false;
      if (val?.error) {
        this.error = true;
        this.isLoading = false;
      } else this.error = false;
      if (val?.access_token) {
        await this.getConfiguracionGral();
        await this.estadoBackupRealizado();
        if (this.boolBackup == false) {
          await this.openModalBackup();
        }
        this.$router.push("/");
      } else {
        this.user.password = "";
        this.isLoading = false;
      }
    }
  },
  methods: {
    ...mapActions({
      getCustomLogo: "AdministracionSistema/getCustomLogo",
      validateUser: "user/validateUser",
      validateTokenMacenaUser: "user/validateTokenMacenaUser",
      userRequireTwoFactorAuthentication:
        "user/userRequireTwoFactorAuthentication",
      generateUserQR: "user/generateUserQR",
      verifyTwoFactorAuthentication: "user/verifyTwoFactorAuthentication",
      saveLoginMacenaWithToken: "user/saveLoginMacenaWithToken",
      getEstadoBackupRealizado:
        "AdministracionSistema/getEstadoBackupRealizado",
      getConfigGral: "AdministracionSistema/getConfigGral"
    }),
    async getLogo() {
      const response = await this.getCustomLogo();
      if (response.data != "") this.customLogo = response.data;
    },
    openModalBackup() {
      return new Promise(resolve => {
        this.showModalBackup = true;
        const confirmBackupHandler = () => {
          this.showModalBackup = false;
          resolve();
        };

        this.$once("confirmBackup", confirmBackupHandler);
      });
    },
    async tokenForm() {
      this.isLoadingTokenBtn = true;
      let response;
      try {
        if (this.isCompanyUser) {
          response = await this.validateTokenMacenaUser(this.token);
        } else {
          const request = {
            userId: this.userValidatedId,
            code: this.token
          };
          response = await this.verifyTwoFactorAuthentication(request);
        }
        if (response) {
          // Si ingresa el token correctamente seteo en true la bandera de validación y lo hago pasar por el método de login
          this.validatedToken = true;
          this.loginForm();
        } else {
          // Si el token es incorrecto lanzo la alerta y no lo dejo ingresar
          this.isLoadingTokenBtn = false;
          this.tokenError = true;
        }
      } catch (error) {
        this.isLoadingTokenBtn = false;
      }
    },
    goToAuthenticateCode() {
      this.userTokenRequest = true;
      this.userGenerateQR = false;
    },
    selectApp(app) {
      this.appSelected = app;
    },
    backToLogin() {
      this.token = this.appSelected = null;
      this.isLoading = this.isLoadingTokenBtn = this.userTokenRequest = this.tokenError = this.userGenerateQR = false;
    },
    confirmAlertAndCloseBackup() {
      // Emite el evento para confirmar el backup
      this.$emit("confirmBackup");
    },
    async estadoBackupRealizado() {
      const response = await this.getEstadoBackupRealizado();
      this.boolBackup = response;
    },
    async getConfiguracionGral() {
      const response = await this.getConfigGral();
      this.cantDiasBackup = response.cantidadDiasControlBackup;
    },
    async loginForm() {
      this.isLoading = true;

      if (this.$refs["login-form"].validate()) {
        if (!this.validatedToken) {
          // Valido primero que el usuario y contraseña sean válidos
          // sin pedir token de usuario (el token que da acceso a los endpoints)
          const validateUser = await this.validateUser(this.user);
          if (validateUser) {
            this.userValidatedId = validateUser.id;
            const userRequireDobleFactor = await this.userRequireTwoFactorAuthentication(
              this.user.username
            );
            if (userRequireDobleFactor == "MacenaCode") {
              // **********Doble autenticación de usuarios de Macena**********
              this.isCompanyUser = true;
              this.userTokenRequest = true;
              this.isLoading = false;
              return;
            } // Consulto si el usuario (del cliente) requiere doble factor de autenticación
            else if (userRequireDobleFactor == "QR") {
              // Genero el QR para que escanee
              this.userGenerateQR = true;
              const qrCode = await this.generateUserQR(validateUser.id);
              this.qrCode = qrCode;
              return;
            } else if (userRequireDobleFactor == "QRCode") {
              // QR ya está generado y escaneado por lo que redirijo directamente a que ingrese el código de autenticación
              this.userTokenRequest = true;
              return;
            }
            // Si el token ya fue validado pasa directamente al inicio de sesión
          }
        }
      }
      try {
        // name: 'fuzzyfish',
        // pass: '4a0',
        const userData = {
          user: this.user.username,
          password: this.user.password,
          grand_type: "password",
          client_id: "gecros"
        };

        this.$store.dispatch("user/setLogin", userData);
        if (this.validatedToken) {
          await this.saveLoginMacenaWithToken({
            token: this.token,
            userName: userData.user,
            isCompanyUser: this.isCompanyUser
          });
        }
      } catch (error) {
        this.isLoading = false;
        this.error = true;
        setTimeout(() => {
          this.user.password = "";
          this.error = false;
        }, 5000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";
.login {
  position: relative;
  height: 100vh;
  width: 100%;
  .full-height {
    height: 90vh;
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
  &:before {
    width: 50%;
    background: #195472;
    display: block;
    content: "";
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    .right {
      order: -1;
    }
    .copy p {
      margin-top: 20px;
    }
  }
  .logo {
    width: 190px;
    @media screen and (max-width: 767px) {
      width: 100px;
      margin-bottom: 10px;
    }
  }
  .copy {
    font-size: 14px;
    text-align: center;
  }
  .green-column {
    color: #fff;
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      background: #195472;
      padding: 20px 0 10px;
    }
  }
  .white-column {
    color: #424242;
    .login-box {
      .title-sign {
        font-size: 27px;
      }
      .small-logo {
        margin-bottom: 20px;
      }
      padding: 40px 28px;
      border: 1px solid #e0e0e0;
      border-radius: 19px;
      width: 100%;
      max-width: 380px;
    }
  }
  .error {
    font-size: 14px;
    padding: 23px;
    text-align: center;
    background: #fff0f0;
    // color: #cb4e4e;
    border-radius: 6px;
    margin: 8px 0;
    p {
      line-height: 16px;
      margin: 0;
      padding: 0;
    }
  }
}
label {
  color: #9e9e9e;
  font-size: 14px;
  margin: 0 0 6px 16px;
  &.active {
    color: #195472;
  }
}
.input-radio {
  width: 100%;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 4px;
  padding: 8px 12px;
  color: #195472;
  &::placeholder {
    color: #9e9e9e;
  }
  &.active {
    border-color: #195472;
  }
}
.v-btn {
  text-transform: capitalize;
  padding: 6px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  height: 38px;
}
::v-deep
  .input-centered
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input {
  text-align: center;
}
.circle-checkbox .v-input--selection-controls .v-input--checkbox::before {
  border-radius: 50%;
  width: 24px; /* Ajusta el tamaño según tus preferencias */
  height: 24px; /* Ajusta el tamaño según tus preferencias */
}
.no-wrap {
  white-space: nowrap;
}
</style>
