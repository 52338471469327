import axios from "@/axios/axios-api";
const actions = {
  async getPagosIngresosVarios(context, payload) {
    const response = await axios.post(
      `api/PagosVarios/post-listar-comprobante`,
      payload
    );
    return response.data.data;
  },
  async getTipoCompPvIv(context, payload) {
    const response = await axios.get(
      `api/PagosVarios/get-tipo-comprobante/${payload}`
    );
    return response.data.data;
  },
  async getTipoCompPvIvAbm(context, payload) {
    const response = await axios.get(
      `api/PagosVarios/get-tipo-comprobante-abm/${payload}`
    );
    return response.data.data;
  },
  async getRetencionesAPagar(context, payload) {
    const response = await axios.post(
      `api/PagosVarios/post-listar-retenciones-a-pagar`,
      payload
    );
    return response.data.data;
  },
  async getComprobantesConImputacionContable(context, payload) {
    try {
      // especificar el tipo de respuesta como "blob" para manejar archivos
      const response = await axios.post(
        `api/PagosVarios/post-listar-comprobante-con-imputacion-contable`,
        payload,
        { responseType: "blob" }
      );
      // Nombre del archivo para la descarga
      const fileName = "Listado comprobantes con imputacion contable.xlsx";
      // Crear un enlace de descarga para el archivo
      const blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // Iniciar la descarga
      document.body.appendChild(link);
      link.click();
      // Limpiar el enlace después de la descarga
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      // Manejo de errores
      if (error.response) {
        let responseToJson = JSON.parse(await error.response.data.text());
        let errorMessage = responseToJson.errors[0];
      }
    }
  },
  async grabarPagosVarios(context, payload) {
    const response = await axios.post(
      `api/PagosVarios/grabar-pagosvarios`,
      payload
    );
    return response.data.data;
  },
  async grabarAnularPagosVarios(context, payload) {
    const response = await axios.post(
      `api/PagosVarios/grabar-anular-pagos-varios`,
      payload
    );
    return response.data.data;
  },
  async getTiposMovBancarios(context, payload) {
    const response = await axios.get(
      `api/MovimientosBancarios/tipos-movimientos-bancarios`
    );
    return response.data.data;
  },
  async getTiposMovBancariosHabilitadoUsuario(context, payload) {
    const response = await axios.get(
      `api/MovimientosBancarios/tipos-movimientos-bancarios-habilitado-usuario`
    );
    return response.data.data;
  },
  async getEstadosMovBancarios(context, payload) {
    const response = await axios.get(
      `api/MovimientosBancarios/estados-movimientos-bancarios`
    );
    return response.data.data;
  },
  async getCtasBancariasMovBancarios(context, payload) {
    const response = await axios.get(
      `api/MovimientosBancarios/obtener-cuenta-bancaria`
    );
    return response.data.data;
  },
  async getMovimientosBancariosFilter(context, payload) {
    const response = await axios.post(
      `api/MovimientosBancarios/listar-movimientos-bancarios-por-filtro`,
      payload
    );
    return response.data.data;
  },
  async getCuentaBancariaByCodigo(context, payload) {
    const response = await axios.get(
      `api/MovimientosBancarios/buscar-cuenta-bancaria-por-codigo/${payload}`
    );
    return response.data.data;
  },
  async getDatosEditPVIV(context, payload) {
    const response = await axios.get(
      `api/PagosVarios/get-datos-edit-pviv/${payload}`
    );
    return response.data.data;
  },
  async getTiposValoresMovBanc(context, payload) {
    const response = await axios.post(
      `api/Proveedores/consultar-tipo-valores-para-MovBanc`,
      payload
    );
    return response.data.data;
  },
  async validateEditPagosVarios(context, payload) {
    const response = await axios.post(
      `api/PagosVarios/validate-edit-pviv/${payload}`,
      payload
    );
    return response.data.data;
  },
  async grabarMovimientoBancario(context, payload) {
    const response = await axios.post(
      `api/MovimientosBancarios/grabar-movimiento-bancario`,
      payload
    );
    return response.data.data;
  },
  async listarBancosByQuery(context, payload) {
    if (payload.input && payload.input.length >= 2) {
      const response = await axios.get(
        `api/ChequeCartera/listar-bancos-by-query?codigo=${payload.input}`
      );
      return response.data.data;
    }
  },
  async deletePagosVarios(context, payload) {
    const response = await axios.delete(
      `api/PagosVarios/eliminar-pagos-varios/${payload}`
    );
    return response;
  },
  async eliminarMovimientoBancario(context, payload) {
    const response = await axios.post(
      `api/MovimientosBancarios/eliminar-movimiento-bancario`,
      payload
    );
    return response.data.data;
  },
  async saveEstadosConciliacionMovBanc(context, payload) {
    const response = await axios.post(
      `api/MovimientosBancarios/save-estados-conciliacion-mov-banc`,
      payload
    );
    return response.data.data;
  },
  async getHistorialEstadosMovBanc(context, payload) {
    const response = await axios.get(
      `api/MovimientosBancarios/get-historial-estados-mov-banc/${payload}`
    );
    return response.data.data;
  },
  async GetTiposValoresRetenciones(context, payload) {
    const response = await axios.get(
      `api/PagosVarios/GetTiposValoresRetenciones`
    );
    return response.data.data;
  },
  async exportarReporteListadoResumen(context, payload) {
    try {
      // Realizar la solicitud POST y recibir el PDF como blob
      const response = await axios.post(
        "api/MovimientosBancarios/reporte-listado-resumen",
        payload,
        {
          responseType: "blob" // Aseguramos que la respuesta sea un archivo binario
        }
      );
      // Crear un blob a partir de la respuesta
      const fileBlob = new Blob([response.data], { type: "application/pdf" });
      // Crear un enlace temporal para descargar el archivo
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(fileBlob);
      link.download = "reporte-listado-resumen.pdf"; // Nombre del archivo que se descargará
      // Simular el clic en el enlace para iniciar la descarga
      link.click();
      // Liberar el objeto URL después de que se descargue el archivo
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error al descargar el reporte:", error);
      throw error;
    }
  }
};
export default actions;
