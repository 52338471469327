const state = {
  filtersProveedores: null,
  filtersOrdenesPago: null,
  filtersDatosToEstadoFactu: null,
  filtersComprobantesProveedor: null,
  filtersBonificacionesYRecargos: null,
  dataItemOpEdit: null,
};

export default state;
