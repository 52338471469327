<template>
  <v-app>
    <IdleDialog v-if="openDialog" :openDialog.sync="openDialog"/>
    <router-view></router-view>
  </v-app>
</template>

<script>
import IdleDialog from '@/components/shared/IdleDialog'; 

export default {
  name: 'Main',
  components: {
    IdleDialog
  },
  data() {
    return {
      openDialog: false,
    };
  },
  onIdle() {
    if (this.$router.currentRoute.name === 'login') return;
    this.openDialog = true;
  },
};

String.prototype.slugify = function() {
  let str = this.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·,/_:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9, -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

String.prototype.deslugify = function() {
  return this.toString()
    .toLowerCase()
    .replace(/-+/g, ' ') // Replace spaces with -
    .replace(/[^\w,\s]+/g, ' ') // Remove all non-word chars
    .replace(/--+/g, ' ') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
};

</script>

<style lang="scss">
@import "@/assets/scss/_forms.scss";
@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/_helpers.scss";

.to-right {
  float: right;
}


/* Fondo gris claro y texto negro para campos deshabilitados */
.theme.v-input--is-disabled {
  background-color: #f5f5f5 !important; /* Fondo gris claro */
  color: #000000 !important;           /* Texto negro oscuro */
  opacity: 1 !important;              
  pointer-events: none;               
}

/* Texto interno */
.v-input.v-input--is-disabled .v-input__control {
  color: #000000 !important; /* Texto interno negro */
}

/* Texto dentro del input */
.v-input.v-input--is-disabled input {
  color: #000000 !important; /* Texto dentro del input negro */
}

</style>
